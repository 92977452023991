import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const reimbursementRoutes = [
  {
    path: routes.reimbursement.travelBudgetPolicy,
    element: lazyLoadRoutes(
      "reimbursement/pages/travelBudgetPolicy/travelBudgetPolicy"
    ),
  },
  {
    path: routes.reimbursement.travelBudgetPolicyAdd,
    element: lazyLoadRoutes(
      "reimbursement/pages/travelBudgetPolicy/travelBudgetPolicyAdd"
    ),
  },
  {
    path: `${routes.reimbursement.travelBudgetPolicyEdit}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/travelBudgetPolicy/travelBudgetPolicyEdit"
    ),
  },
  {
    path: `${routes.reimbursement.travelBudgetPolicyView}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/travelBudgetPolicy/travelBudgetPolicyView"
    ),
  },
  {
    path: routes.reimbursement.approvalMatrix,
    element: lazyLoadRoutes(
      "reimbursement/pages/approvalMatrix/approvalMatrix"
    ),
  },
  {
    path: `${routes.reimbursement.approvalMatrixEdit}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/approvalMatrix/approvalMatrixEdit"
    ),
  },
  {
    path: `${routes.reimbursement.approvalMatrixView}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/approvalMatrix/approvalMatrixView"
    ),
  },
  {
    path: routes.reimbursement.approvalMatrixForm,
    element: lazyLoadRoutes(
      "reimbursement/pages/approvalMatrix/approvalMatrixForm"
    ),
  },
  {
    path: routes.reimbursement.sittingFaceWindow,
    element: lazyLoadRoutes(
      "reimbursement/pages/sittingFaceWindow/sittingFaceWindow"
    ),
  },
  {
    path: `${routes.reimbursement.sittingFaceWindowEdit}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/sittingFaceWindow/editSittingFaceWindow"
    ),
  },
  {
    path: `${routes.reimbursement.sittingFaceWindowView}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/sittingFaceWindow/viewSittingFaceWindow"
    ),
  },
  {
    path: routes.reimbursement.sittingFaceWindowForm,
    element: lazyLoadRoutes(
      "reimbursement/pages/sittingFaceWindow/sittingFaceWindowForm"
    ),
  },
  {
    path: routes.reimbursement.accuredBalancedDetails,
    element: lazyLoadRoutes(
      "reimbursement/pages/accured&Balancedetails/accured&Balancedetails"
    ),
  },
  {
    path: routes.reimbursement.accuredSelectedDetails,
    element: lazyLoadRoutes(
      "reimbursement/pages/accured&Balancedetails/accuralSelectedDetails"
    ),
  },
  {
    path: routes.reimbursement.checkAccuralSelectedDetails,
    element: lazyLoadRoutes(
      "reimbursement/pages/accured&Balancedetails/checkAccuralSelectedDetails"
    ),
  },
  {
    path: routes.reimbursement.checkAccuralDetails,
    element: lazyLoadRoutes(
      "reimbursement/pages/accured&Balancedetails/checkAccuralDetails"
    ),
  },
  {
    path: routes.reimbursement.processAdminReimbursement,
    element: lazyLoadRoutes(
      "reimbursement/pages/processAdmin/reimbursementProcessList"
    ),
  },
  {
    path: routes.reimbursement.processAdminReimbursementAdd,
    element: lazyLoadRoutes(
      "reimbursement/pages/processAdmin/reimbursementProcessAdd"
    ),
  },
  {
    path: `${routes.reimbursement.processAdminReimbursementView}/:id`,
    element: lazyLoadRoutes(
      "reimbursement/pages/processAdmin/reimbursementProcessView"
    ),
  },
  {
    path: `${routes.reimbursement.processAdminReimbursementReport}`,
    element: lazyLoadRoutes(
      "reimbursement/pages/processAdmin/reimbursementReport"
    ),
  },
];
