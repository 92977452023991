import routes from "./routes";
import { MdDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { GiArchiveRegister } from "react-icons/gi";
import { FaGraduationCap } from "react-icons/fa6";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdHolidayVillage } from "react-icons/md";
import { IoTime } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { ImExit } from "react-icons/im";
import { BiSolidBank } from "react-icons/bi";
import { RiRefund2Line } from "react-icons/ri";
import { TbFilePercent } from "react-icons/tb";
import { FaUserShield } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaSortAmountDown } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoMdMail } from "react-icons/io";
import { TbBrandGoogleBigQuery } from "react-icons/tb";
import { IoGameController } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { PiNewspaperClippingDuotone } from "react-icons/pi";
import { FaSackDollar } from "react-icons/fa6";
import { GrCloudSoftware } from "react-icons/gr";
export const LOOK_UP_TYPE = {
  gender: "gender",
  prefix: "prefix",
  paymentMode: "paymentmode",
  bloodGroup: "bgroup",
  maritalstatus: "maritalstatus",
  recruitmentMode: "recruitment_mode",
  DLType: "DL_Type",
  country: "country",
  state: "state",
  holidayType: "holiday_type",
  leaveYearType: "leave_year_type",
};
// admin sidebar
export const ADMIN_SIDEBAR_NAV_MENU_ITEMS = {
  // admin sidebar mainmenu
  mainMenu: [
    {
      name: "Dashboard",
      icon: <MdDashboard className="icons-dashboard" />,
      path: routes.dashboard,
    },
    // {
    //   name: "Employee",
    //   path: routes.employee.listEmployee,
    // },
    {
      name: "Employee",
      icon: <FaUsers className="icons-dashboard" />,
      path: routes.employee.listEmployeeNew,
    },
    {
      name: "Employee Confirm.",
      icon: <FaUsers className="icons-dashboard" />,
      path: routes.employeeConfirmation.listEmployeeConfirm,
    },
    {
      name: "Salary Process",
      icon: <FaSackDollar className="icons-dashboard" />,
      path: routes.salaryProcess.salaryProcess,
    },
  ],
  // admin sidebar submenu
  subMenu: [
    {
      name: "Attendance",
      icon: <GiArchiveRegister className="icons-dashboard me-2" />,
      children: [
        {
          name: "Attendance Summary",
          path: routes.attendance.attendanceList,
        },
        {
          name: "Attendance Log",
          path: routes.attendance.attendanceLog,
        },
        {
          name: "Log Import",
          path: routes.attendance.LogImport,
        },
        {
          name: "Auto Attendance",
          path: routes.attendance.autoAttendance,
        },
        // {
        //   name: "Monthly Leave Report",
        //   path: routes.attendance.monthlyLeavereport,
        // },
      ],
    },

    {
      name: "Masters",
      icon: <FaGraduationCap className="icons-dashboard me-2" />,
      children: [
        // {
        //   name: "Company",
        //   path: routes.master.listCompany,
        // },
        {
          name: "Company",
          path: routes.master.listCompanyNew,
        },
        {
          name: "Qualification",
          path: routes.master.qualification,
        },
        {
          name: "Project",
          path: routes.master.project,
        },
        {
          name: "Grade",
          path: routes.master.grade,
        },
        {
          name: "Division",
          path: routes.master.division,
        },
        {
          name: "Designation",
          path: routes.master.designation,
        },
        {
          name: "Department",
          path: routes.master.department,
        },
        {
          name: "Category",
          path: routes.master.category,
        },
        {
          name: "Branch",
          path: routes.master.branch,
        },
        {
          name: "Bank",
          path: routes.master.bank,
        },
        {
          name: "Skills",
          path: routes.master.skills,
        },
        {
          name: "Cost Center",
          path: routes.master.costCenter,
        },
        {
          name: "Document Type",
          path: routes.master.documentType,
        },
        {
          name: "Location",
          path: routes.master.location,
        },
        {
          name: "Bonus",
          path: routes.master.bonus,
        },
        {
          name: "Essentials",
          path: routes.master.essentials,
        },
      ],
    },
    // payroll
    {
      name: "Payroll",
      icon: <FaMoneyCheckDollar className="icons-dashboard me-2" />,
      children: [
        {
          name: "Earning Salary Head",
          path: routes.payroll.EarningSalaryHead,
        },
        {
          name: "Deducation Head",
          path: routes.payroll.DeducationHead,
        },
        {
          name: "Other Head",
          path: routes.payroll.OtherHead,
        },
        {
          name: "GL Code Setting",
          path: routes.payroll.GlCodeSetting,
        },

        {
          name: "LWF Rule Setting",
          path: routes.payroll.LwfRuleSetting,
        },
        {
          name: "Professional Tax",
          path: routes.payroll.ProfessionalTax,
        },

        // {
        //   name: "Attandance Process",
        //   path: routes.payroll.AttandanceReport,
        // },
        {
          name: "CTC Component",
          path: routes.payroll.CtcComponentSetting,
        },
        {
          name: "Salary Structure",
          path: routes.payroll.SalaryStructure,
        }
      ],
    },

    // monthly calculation
    {
      name: "Monthly Calc.",
      icon: <FaMoneyCheckDollar className="icons-dashboard me-2" />,
      children: [
        {
          name: "Earning Variables",
          path: routes.monthlyCalculation.monthlyCalculation,
        },
        {
          name: "Deduction Variables",
          path: routes.monthlyCalculation.deductionVariable,
        },
        {
          name: "Full & Final",
          path: routes.exitfullfinal.exitTypeFullAndFinal,
        },
        {
          name: "Promotion and Transfer",
          path: routes.payroll.PromotionAndTransfer,
        },
        {
          name: "Increment Arrear",
          path: routes.payroll.IncrementArrear,
        },
        {
          name: "Lwp Arrear",
          path: routes.payroll.LwpArrear,
        },
        {
          name: "Other Arrear",
          path: routes.payroll.OtherArrear,
        },
        {
          name: "Reimb. Payment",
          path: routes.reimbursement.processAdminReimbursement,
        },
      ],
    },

    // Additional Calculations
    {
      name: "Additional Calc.",
      icon: <FaMoneyCheckDollar className="icons-dashboard me-2" />,
      children: [
        {
          name: "Bonus Calculation",
          path: routes.payroll.BonusCalculation,
        },
        {
          name: "Gratuity Calculation",
          path: routes.payroll.GrautityCalculation,
        },
        {
          name: "Over Time Calculation",
          path: routes.payroll.OverTime,
        },
        {
          name: "Leave Encashment",
          path: routes.payroll.LeaveEncashment,
        },
        {
          name: "Employee Increment",
          path: routes.payroll.EmployeeIncrement,
        },
        // {
        //   name: "Arrear Calculation",
        //   path: routes.payroll.ArrearBaiseList,
        // },
      ],
    },

    // Loan And Advance Settings
    {
      name: "Loan & Advanced",
      icon: <BiSolidBank className="icons-dashboard me-2" />,
      children: [
        {
          name: "Dashboard",
          path: routes.loanAndAdvance.dashboard,
        },
        {
          name: "Loan & Advanced Policy",
          path: routes.loanAndAdvance.loanAndPolicyList,
        },
        {
          name: "Loan Disbursement",
          path: routes.loanAndAdvance.loanDisbursementList,
        },
        {
          name: "Loan Finance",
          path: routes.loanAndAdvance.loanFinanceList,
        },
        {
          name: "Loan Reports",
          path: routes.loanAndAdvance.loanReport,
        },
      ],
    },

    // leave and holiday
    {
      name: "Leave & Holiday",
      icon: <MdHolidayVillage className="icons-dashboard me-2" />,
      children: [
        {
          name: "Leave Type",
          path: routes.leave.leaveType,
        },
        {
          name: "Leave Settings",
          path: routes.leave.leaveSetting,
        },
        {
          name: "Holiday",
          path: routes.leave.holiday,
        },
        {
          name: "Manual Leave",
          path: routes.leave.manualLeave,
        },
        {
          name: "Leave Approval ",
          path: routes.leave.leaveApplication,
        },
        {
          name: "Leave Reports ",
          path: routes.leave.leaveReport,
        },
      ],
    },
    // shift
    {
      name: "Shift",
      icon: <IoTime className="icons-dashboard me-2" />,
      children: [
        {
          name: "Shift Master",
          path: routes.shift.list,
        },
        {
          name: "Shift Assigned",
          path: routes.shift.shiftAssign,
        },
      ],
    },

    // assets
    {
      name: "Assets",
      icon: <GiPayMoney className="icons-dashboard me-2" />,
      children: [
        {
          name: "Assets Group",
          path: routes.assets.group,
        },
        {
          name: "Assets List",
          path: routes.assets.list,
        },
        {
          name: "Assets Issued List",
          path: routes.assets.movementList,
        },
        // {
        //   name: "Assets Issued List",
        //   path: routes.assets.requisitionList,
        // },
        {
          name: "Assets Request List",
          path: routes.assets.requestList,
        },
      ],
    },
    // Exit Process
    {
      name: "Exit Process",
      icon: <ImExit className="icons-dashboard me-2" />,
      children: [
        {
          name: "Exit Type",
          path: routes.exit.exitType,
        },
        {
          name: "Exit Clearance Department",
          path: routes.exit.exitClearanceDepartment,
        },
        {
          name: "Notice Days Settings",
          path: routes.exit.noticeDaysSetting,
        },
        {
          name: "Interview Incharge",
          path: routes.exit.interviewIncharge,
        },
        {
          name: "Interview Questions",
          path: routes.exit.interviewQuestions,
        },
        {
          name: "Clearance Description",
          path: routes.exit.departmentClearance,
        },
      ],
    },

    // Exit Process
    {
      name: "F & F Process",
      // icon: <ImExit className="icons-dashboard me-2" />,
      icon: <i className="fa-solid fa-handshake icons-dashboard me-2"></i>,
      children: [
        {
          name: "Exit Full & Final ",
          path: routes.exitfullfinal.exitTypeFullAndFinal,
        },
      ],
    },

    //Reimbursement
    {
      name: "Reimbursement",
      icon: <RiRefund2Line className="icons-dashboard me-2" />,
      children: [
        {
          name: "Reimb. Head",
          path: routes.reimbursement.sittingFaceWindow,
        },
        {
          name: "Travel Budget & Policy",
          path: routes.reimbursement.travelBudgetPolicy,
        },
        {
          name: "Reimb. Approval Matrix",
          path: routes.reimbursement.approvalMatrix,
        },
        {
          name: "Accured & Balance Details",
          path: routes.reimbursement.accuredBalancedDetails,
        },
      ],
    },

    //TDS
    {
      name: "TDS",
      icon: <TbFilePercent className="icons-dashboard me-2" />,
      children: [
        {
          name: "TDS Settings",
          path: routes.tds.TDSSettings,
        },
        {
          name: "80C & US VI-A Items",
          path: routes.tds.TDSUSVIAItems,
        },
        {
          name: "Type of Perks",
          path: routes.tds.TDSPerksMaster,
        },
        {
          name: "TDS Calculation",
          path: routes.tds.TDSIncomeArrearExemption,
        },
      ],
    },
    // TDS Return Process
    {
      name: "TDS Return Process",
      icon: <FaSortAmountDown className="icons-dashboard me-2" />,
      children: [
        {
          name: "TDS Challan",
          path: routes.tds.TDSChallan,
        },

        {
          name: "Company Details",
          path: routes.tds.TDSCompanyDetails,
        },
      ],
    },
    //TDS APPROVER
    {
      name: "TDS Decl./Invest.",
      icon: <FaMoneyBillTrendUp className="icons-dashboard me-2" />,
      children: [
        {
          name: "Approver",
          path: routes.tds.TDSBaseWindow,
        },
      ],
    },

    //OnBoarding
    {
      name: "On Boarding",
      icon: <FaMoneyBillTrendUp className="icons-dashboard me-2" />,
      children: [
        {
          name: "Dashboard",
          path: routes.onBoarding.onBoardingDashboard,
        },
        {
          name: "Letter Template",
          path: routes.onBoarding.letterTemplate,
        },
        {
          name: "Assign Letter",
          path: routes.onBoarding.assignLetter,
        },
      ],
    },
  ],
};

export const ESS_SIDEBAR_NAV_MENU_ITEMS = {
  mainMenu: [
    {
      name: "Dashboard",
      icon: <MdDashboard className="icons-dashboard" />,
      path: routes.ess.dashboard,
    },
    {
      name: "Profile",
      icon: <CgProfile className="icons-dashboard" />,
      path: routes.ess.profile,
    },
  ],

  subMenu: [
    {
      name: "Attendance & Leave",
      icon: <GiArchiveRegister className="icons-dashboard me-2" />,
      children: [
        {
          name: "Mark Attendance",
          path: routes.ess.attendance.markAttendance,
        },
        {
          name: "Leave Application",
          path: routes.ess.attendance.leaveApplication,
        },

        {
          name: "Leave Approval",
          path: routes.ess.attendance.leaveApproval,
        },
        {
          name: "Missed Punch",
          path: routes.ess.attendance.missedPunch,
        },
        {
          name: "Missed Punch Approver",
          path: routes.ess.attendance.missedPuchApprover,
        },
        {
          name: "Leave Attendance Details",
          path: routes.ess.attendance.leaveAttendanceDetails,
        },
      ],
    },

    {
      name: "Assets",
      icon: <GiPayMoney className="icons-dashboard me-2" />,
      children: [
        {
          name: "My Assets List",
          path: routes.ess.assets.list,
        },
      ],
    },

    {
      name: "Shift",
      icon: <TbFilePercent className="icons-dashboard me-2" />,
      children: [
        {
          name: "Shift Ess",
          path: routes.ess.essShift.essEmployeeShiftList,
        },
        {
          name: "Shift Assign",
          path: routes.ess.essShift.essEmployeeShiftAssign,
        },
      ],
    },
    {
      name: "Exit Process",
      icon: <ImExit className="icons-dashboard me-2" />,
      children: [
        {
          name: "my Exit application",
          path: routes.ess.essExitProcess.employeeExitApplication,
        },
        {
          name: "Self Exit Clearance",
          path: routes.ess.essExitProcess.selfExitClearance,
        },
        {
          name: "Exit Interview",
          path: routes.ess.essExitProcess.exitInterviewEmail,
        },
      ],
    },
    {
      name: "Exit Approval Process",
      icon: <ImExit className="icons-dashboard me-2" />,
      children: [
        {
          name: "my Exit approval",
          path: routes.ess.essExitProcess.employeeExitApproval,
        },
        {
          name: "Self Exit Approval",
          path: routes.ess.essExitProcess.selfExitClearanceApproval,
        },
        {
          name: "Exit Interview Approval",
          path: routes.ess.essExitProcess.approvalExitInterview,
        },
      ],
    },
    {
      name: "Reimbursement Process",
      icon: <RiRefund2Line className="icons-dashboard me-2" />,
      children: [
        {
          name: "Travel Request List",
          path: routes.ess.essReimbursementProcess.travelRequestList,
        },
        {
          name: "Other Misc. Claim",
          path: routes.ess.essReimbursementProcess.otherLtaTravelList,
        },
        {
          name: "LTA Claim",
          path: routes.ess.essReimbursementProcess.ltaClaimList,
        },
        {
          name: "Travel Claim Window",
          path: routes.ess.essReimbursementProcess.travelClaimWindowList,
        },
      ],
    },
    {
      name: "Reimbursement Approval Process",
      icon: <RiRefund2Line className="icons-dashboard me-2" />,
      children: [
        {
          name: "Travel Request",
          path: routes.ess.essReimbursementProcess.travelRequestApprovalList,
        },
        {
          name: "HR and Admin",
          path: routes.ess.essReimbursementProcess.hrAdminApprovalList,
        },
        {
          name: "Travel Advanced(Finance)",
          path: routes.ess.essReimbursementProcess.financeDepartmentList,
        },
        {
          name: "Other Misc. Approval",
          path: routes.ess.essReimbursementProcess.otherLtaApprovedList,
        },
        {
          name: "Other Misc. Finance Approval",
          path: routes.ess.essReimbursementProcess.otherLtaFinanceApprovedList,
        },
        {
          name: "LTA Claim Approval",
          path: routes.ess.essReimbursementProcess.ltaClaimApprovedList,
        },
        {
          name: "LTA Finance Approval",
          path: routes.ess.essReimbursementProcess.ltaClaimFinanceApprovedList,
        },
        {
          name: "Travel Claim Approval",
          path: routes.ess.essReimbursementProcess
            .travelClaimWindowApproverList,
        },
        {
          name: "Travel Payment Process",
          path: routes.ess.essReimbursementProcess.travelPaymentProcessList,
        },
      ],
    },
    {
      name: "TDS",
      icon: <TbFilePercent className="icons-dashboard me-2" />,
      children: [
        {
          name: "TDS Calculation",
          path: routes.ess.essTdsCalculationProcess.tdsCalculation,
        },
        {
          name: "TDS Approver",
          path: routes.ess.essTdsCalculationProcess.tdsApprover,
        },
        // {
        //   name: "TDS Return Process",
        //   path: routes.ess.essTdsCalculationProcess.tdsChallan,
        // },
      ],
    },

    // Loan And Advance Settings
    {
      name: "Loan & Advanced",
      icon: <BiSolidBank className="icons-dashboard me-2" />,
      children: [
        {
          name: "Loan Apply",
          path: routes.ess.loan.loanApply,
        },
        {
          name: "Loan Approve",
          path: routes.ess.loan.loanApprove,
        },
        {
          name: "Loan Finance",
          path: routes.ess.loan.loanFinanceList,
        },
        {
          name: "Loan Finance Approve",
          path: routes.ess.loan.loanFinanceApprover,
        },
        {
          name: "Emp. Loan & Adv. Details Report",
          path: routes.ess.loan.loanReport,
        },
        {
          name: "Approval Advanced Details Report",
          path: routes.ess.loan.loanApprovalReport,
        },
      ],
    },

    //ess OnBoarding
    {
      name: "On Boarding",
      icon: <FaMoneyBillTrendUp className="icons-dashboard me-2" />,
      children: [
        {
          name: "Generate Offer",
          path: routes.ess.essOnBoarding.onBoardingList,
        },
      ],
    },
  ],
};

// adminControls SideBar
export const ADMIN_CONTROLS_NAV_MENU_ITEMS = {
  subMenu: [
    {
      mainHeadingName: "Admin Controls",
      mainIcon: <IoGameController className="icons-dashboard me-2" />,
      name: "Query",
      icon: <TbBrandGoogleBigQuery className="icons-dashboard me-2" />,
      children: [
        {
          name: "Query Management",
          path: routes.queryManagement.queryManagementList,
        },
      ],
    },

    {
      name: "Upload Controls",
      icon: <FaUpload className="icons-dashboard me-2" />,

      children: [
        {
          name: "Upload Contorl",
          path: routes.controls.uploadControl,
        },
      ],
    },
    {
      name: "News & Events",
      icon: <PiNewspaperClippingDuotone className="icons-dashboard me-2" />,

      children: [
        {
          name: "Announcement",
          path: routes.controls.announcement,
        },
        {
          name: "policies",
          path: routes.controls.policies,
        },
        {
          name: "Events Gallery",
          path: routes.controls.eventGallery,
        },

        {
          name: "News",
          path: routes.controls.news,
        },
      ],
    },

    //mail
    {
      name: "Mail Communication",
      icon: <IoMdMail className="icons-dashboard me-2" />,
      children: [
        {
          name: "Mail Setting",
          path: routes.mail.mailSettting,
        },
        {
          name: "Third party  Mail Setting",
          path: routes.mail.ThirdPartymailSettting,
        },
        {
          name: "Mailer",
          path: routes.mail.mailer,
        },
      ],
    },
    // users
    {
      name: "Users",
      icon: <FaUserShield className="icons-dashboard me-2" />,
      children: [
        {
          name: "Admin Users",
          path: routes.users.admin.userList,
        },
        {
          name: "Ess Users",
          path: routes.users.ess.userList,
        },

        {
          name: "Ess User Role",
          path: routes.users.ess.roleList,
        },
        {
          name: "Admin Role Create",
          path: routes.users.admin.adminRoleCreate,
        },
        {
          name: "Admin User Assign",
          path: routes.users.admin.roleList,
        },
        {
          name: "Ess Role Create",
          path: routes.users.ess.essRoleCreate,
        },
        {
          name: "Ess User Assign",
          path: routes.users.ess.essUserAssign,
        },
        {
          name: "Mobile Rights",
          path: routes.users.ess.mobileRights,
        },
      ],
    },
    //software log report
    {
      name: "Software Log Report",
      icon: <GrCloudSoftware className="icons-dashboard me-2" />,
      children: [
        {
          name: "Software Log Report",
          path: routes.controls.softwareLogReport,
        },
      ],
    },
    {
      name: "Approval Setting",
      icon: <FaUpload className="icons-dashboard me-2" />,

      children: [
        {
          name: "Approval Setting",
          path: routes.controls.approvalSetting,
        },
      ],
    },
    {
      name: "Import Utility",
      icon: <FaUpload className="icons-dashboard me-2 rotate-180" />,

      children: [
        {
          name: "Import Utility",
          path: routes.controls.importUtility,
        },
      ],
    },
  ],
};
