import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const shiftRoutes = [
  {
    path: routes.shift.list,
    element: lazyLoadRoutes("shift/master/index"),
  },
  {
    path: routes.shift.addShift,
    element: lazyLoadRoutes("shift/master/add"),
  },
  {
    path: `${routes.shift.addShift}/:id`,
    element: lazyLoadRoutes("shift/master/add"),
  },
  {
    path: routes.shift.shiftAssign,
    element: lazyLoadRoutes("shift/assigned/index"),
  },
  {
    path: routes.shift.addShiftAssign,
    element: lazyLoadRoutes("shift/assigned/add"),
  },
  {
    path: `${routes.shift.addShiftAssign}/:id`,
    element: lazyLoadRoutes("shift/assigned/add"),
  },
];
