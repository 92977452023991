import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const onBoardingRoutes = [
  {
    path: routes.onBoarding.onBoardingDashboard,
    element: lazyLoadRoutes("onBoarding/pages/dashboard"),
  },
  {
    path: routes.onBoarding.letterTemplate,
    element: lazyLoadRoutes("onBoarding/pages/letter-template"),
  },
  {
    path: routes.onBoarding.assignLetter,
    element: lazyLoadRoutes("onBoarding/pages/assign-letter"),
  },
];
