import { getCookie } from "../../utils/cookie-service";
import { UPDATE_USER } from "../mutations";

let authUser = getCookie("authUser");

const initialState = {
  currentUser: authUser,
  isAuthenticated: authUser !== undefined ? true : false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: action.payload !== null,
      };
    default:
      return state;
  }
}
