import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const queryRoutes = [
  {
    path: routes.queryManagement.queryManagementList,
    element: lazyLoadRoutes("queryManagement/pages/queryManagementList"),
  },
  {
    path: routes.queryManagement.queryManagementAdd,
    element: lazyLoadRoutes("queryManagement/pages/queryManagementApproval"),
  },
];
