import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/shared/header";
import Notification from "./components/shared/notification";
import Spinner from "./components/shared/spinner";
import SideNav from "./components/shared/sidenav";
import { AppRoutes } from "./navigation/app-routes";
import { getCookie } from "./utils/cookie-service";
import { withRouter } from "./utils/withRouter";
import { updateCompany } from "./store/global/actions";
import { AuthRoutes } from "./navigation/auth-routes";

function App() {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const companyId = useSelector((state) => state.global.companyId);
  const activeMonth = useSelector((state) => state.global.activeMonth);
  const [activeSidebar, setActiveSidebar] = useState(false);

  const showSidevarHandler = () => {
    setActiveSidebar(!activeSidebar);
  };
  useEffect(() => {
    const companyId = getCookie("hrms-companyId");
    if (companyId) {
      dispatch(updateCompany(companyId));
    }
  }, []);
  return (
    <>
      <Notification />
      <Spinner />
      {authUser.isAuthenticated ? (
        <div className="wrapper">
          {
            /* logic only to render sidebar if usertype !== admin || company id !== null */
            companyId !== null || authUser.currentUser.userType !== "Admin" ? (
              <>
                <SideNav activeSidebar={activeSidebar} />
                <Header
                  showSidevarHandler={showSidevarHandler}
                  activeSidebar={activeSidebar}
                />
                <div
                  className={`page-wrapper ${
                    activeSidebar == true
                      ? "page-wrapper-active"
                      : "page-wrapper-disactive"
                  }`}
                >
                  <AppRoutes />
                </div>
              </>
            ) : (
              <>
                <AppRoutes />
              </>
            ) 
          }
        </div>
      ) : (
        <div className="wrapper">
          <AuthRoutes />
        </div>
      )}
    </>
  );
}
export default withRouter(App);
