import { DEFAULT_ACTION, LOG_OUT, ADD_EMPLOYEE } from "../action-types";
import {
  HIDE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SHOW_LOADING,
  UPDATE_USER,
  UPDATE_COMPANY,
  UPDATE_COMPANY_LIST,
  UPDATE_ACTIVE_MONTH,
  UPDATE_SIDEBAR,
} from "../mutations";
import {
  AuthenticationError,
  NetworkError,
  ClientError,
  ServerError,
} from "../../network/error-type";
import { NOTIFICATION_MESSAGES } from "../constants";
import { removeCookie } from "../../utils/cookie-service";
import httpService from "../../network/http-service";
import { API_ENDPOINT } from "../api-action";

export function add(action, payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({ action, data: payload });
      if (response.data.status.toLowerCase() === "fail")
        dispatch(
          updateNotification({
            variant: "error",
            message: response.data.message,
          })
        );
      else
        dispatch(
          updateNotification({
            variant: "success",
            message: response.data.status,
          })
        );
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(ADD_EMPLOYEE, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function update(action, payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.put({ action, data: payload });
      if (response.data.status.toLowerCase() === "success")
        dispatch(
          updateNotification({
            variant: "success",
            message: response.data.status,
          })
        );
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(ADD_EMPLOYEE, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getList(action, segment) {
  return async function (dispatch, state) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const companyId = segment === "" ? "" : state().global.companyId;
      const response = await httpService.get({
        action,
        segment: segment || companyId,
      });
      return response?.data?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getById(action, id) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action, segment: id });
      return response?.data?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
export function getMasterList(action, segment) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action, segment: segment });
      return response?.data || {};
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteById(action, payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.delete({ action, data: payload });
      if (response.data.status.toLowerCase() === "success")
        dispatch(
          updateNotification({
            variant: "success",
            message: response.data.status,
          })
        );
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getLookUpList(segment) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.master.lookUpList,
        segment,
      });
      return response.data?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getCompanyList() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.master.companyList,
      });
      dispatch({ type: UPDATE_COMPANY_LIST, payload: response.data?.data });
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function showNotification() {
  return function (dispatch) {
    dispatch({ type: HIDE_NOTIFICATION });
  };
}

export function updateNotification(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_NOTIFICATION, payload: payload });
  };
}

export function updateSpinner(payload) {
  return function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: payload });
  };
}

export function updateCompany(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_COMPANY, payload: payload });
  };
}
export function updateActiveMonth(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_ACTIVE_MONTH, payload: payload });
  };
}
export function updateSideBar(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_SIDEBAR, payload: payload });
  };
}

export function handleError(action, error) {
  return function (dispatch) {
    action = action || DEFAULT_ACTION;

    if (error instanceof ClientError) {
      const errorObj = NOTIFICATION_MESSAGES[action].error;
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          title: errorObj.title,
          variant: errorObj.variant,
          message: error.message || errorObj.message,
        },
      });
    } else if (error instanceof ServerError) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION_MESSAGES[action].error,
      });
    } else if (error instanceof NetworkError) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION_MESSAGES[action].error,
      });
    } else if (error instanceof AuthenticationError) {
      removeCookie("veriipro-user");
      removeCookie("veriipro-token");
      dispatch({ type: UPDATE_USER, payload: null });
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION_MESSAGES[LOG_OUT],
      });
    } else {
      const errorObj = NOTIFICATION_MESSAGES[action].error;
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          title: errorObj.title,
          variant: errorObj.variant,
          message: error.message || errorObj.message,
        },
      });
    }
  };
}
