import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const loanAndAdvanceRoutes = [
  {
    path: routes.loanAndAdvance.dashboard,
    element: lazyLoadRoutes("loanAndAdvance/pages/dashboard"),
  },
  {
    path: routes.loanAndAdvance.loanAndPolicyList,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanAndPolicyList"),
  },
  {
    path: routes.loanAndAdvance.loanAndAdvanceSettings,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanSettings"),
  },
  {
    path: routes.loanAndAdvance.loanDisbursementList,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanDisbursement/loanDisbursement"),
  },
  {
    path: `${routes.loanAndAdvance.loanDisbursementView}/:id`,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanDisbursement/loanView"),
  },
  {
    path: routes.loanAndAdvance.loanFinanceList,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanFinance/loanFinanceList"),
  },
  {
    path: `${routes.loanAndAdvance.loanFinanceView}/:id`,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanFinance/loanFinanceView"),
  },
  {
    path: routes.loanAndAdvance.loanReport,
    element: lazyLoadRoutes("loanAndAdvance/pages/loanReports/loanReportsPage"),
  },
];
