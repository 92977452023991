import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essExitProcessRoutes = [
  {
    path: routes.ess.essExitProcess.employeeExitApplication,
    element: lazyLoadRoutes("ess/exit/pages/employeeExitApplication"),
  },
  {
    path: routes.ess.essExitProcess.employeeExitApproval,
    element: lazyLoadRoutes("ess/exit/pages/employeeExitApproval"),
  },
  {
    path: routes.ess.essExitProcess.selfExitClearance,
    element: lazyLoadRoutes("ess/exit/pages/selfExitClearance"),
  },
  {
    path: routes.ess.essExitProcess.selfExitClearanceApproval,
    element: lazyLoadRoutes("ess/exit/pages/selfExitApproval"),
  },
  {
    path: routes.ess.essExitProcess.exitInterviewEmail,
    element: lazyLoadRoutes("ess/exit/pages/exitInterviewEmail"),
  },
  {
    path: routes.ess.essExitProcess.approvalExitInterview,
    element: lazyLoadRoutes("ess/exit/pages/approvalExitInterview"),
  },
  {
    path: routes.ess.essExitProcess.supervisorExitClearance,
    element: lazyLoadRoutes("ess/exit/pages/supervisorExitClearance"),
  },
  {
    path: routes.ess.essExitProcess.viewExitInterView,
    element: lazyLoadRoutes("ess/exit/pages/viewExitInterView"),
  },
  {
    path: routes.ess.essExitProcess.viewExitInterViewTab,
    element: lazyLoadRoutes("ess/exit/pages/viewExitInterViewTab"),
  },
  {
    path: routes.ess.essExitProcess.viewExitInterViewApprovalTab,
    element: lazyLoadRoutes("ess/exit/pages/viewExitInterviewApprovalTab"),
  },
];
