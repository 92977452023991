import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essAttendanceLeaveRoutes = [
  {
    path: routes.ess.attendance.markAttendance,
    element: lazyLoadRoutes("ess/attendance-leave/pages/attendance"),
  },
  {
    path: routes.ess.attendance.leaveApplication,
    element: lazyLoadRoutes("ess/attendance-leave/pages/leave-application"),
  },

  {
    path: routes.ess.attendance.leaveApproval,
    element: lazyLoadRoutes("ess/attendance-leave/pages/leave-approval"),
  },
  {
    path: routes.ess.attendance.missedPunch,
    element: lazyLoadRoutes("ess/attendance-leave/pages/missed-punch"),
  },
  {
    path: routes.ess.attendance.missedPuchApprover,
    element: lazyLoadRoutes("ess/attendance-leave/pages/missed-punch-approver"),
  },
  {
    path: routes.ess.attendance.leaveAttendanceDetails,
    element: lazyLoadRoutes(
      "ess/attendance-leave/pages/leave-attendance-details"
    ),
  },
];
