import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essShiftRoutes = [
  {
    path: routes.ess.essShift.essEmployeeShiftList,
    element: lazyLoadRoutes("ess/shift/pages/index"),
  },
  {
    path: routes.ess.essShift.essEmployeeShiftAssign,
    element: lazyLoadRoutes("ess/shift/pages/shiftAssign"),
  },
];
