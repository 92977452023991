import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const SalaryProcessRoutes = [
  {
    path: routes.salaryProcess.salaryProcess,
    element: lazyLoadRoutes("salary-process/pages/salary-process"),
  },
  {
    path: routes.salaryProcess.processList,
    element: lazyLoadRoutes("salary-process/pages/process-list"),
  },
];
