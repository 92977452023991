import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const exitRoutes = [
  {
    path: routes.exit.exitType,
    element: lazyLoadRoutes("exit/pages/exitType"),
  },
  {
    path: routes.exit.exitClearanceDepartment,
    element: lazyLoadRoutes("exit/pages/exitClearanceDepartment"),
  },
  {
    path: routes.exit.exitClearanceDepartmentAdd,
    element: lazyLoadRoutes("exit/pages/exitClearanceDepartmentAdd"),
  },
  {
    path: routes.exit.noticeDaysSetting,
    element: lazyLoadRoutes("exit/pages/noticeDaysSetting"),
  },
  {
    path: routes.exit.interviewIncharge,
    element: lazyLoadRoutes("exit/pages/interviewIncharge"),
  },
  {
    path: routes.exit.addInterViewIncharge,
    element: lazyLoadRoutes("exit/pages/addInterViewIncharge"),
  },
  {
    path: routes.exit.interviewQuestions,
    element: lazyLoadRoutes("exit/pages/interviewQuestions"),
  },
  {
    path: routes.exit.addInterviewQuestions,
    element: lazyLoadRoutes("exit/pages/AddInterviewQuestions"),
  },
  {
    path: routes.exit.departmentClearance,
    element: lazyLoadRoutes("exit/pages/departmentClearance"),
  },

  // exit Full & Final
  {
    path: routes.exitfullfinal.exitTypeFullAndFinal,
    element: lazyLoadRoutes("exitFullAndFinal/pages/exitFullFinalList"),
  },
  {
    path: routes.exitfullfinal.exitTypeFullAndFinalFilter,
    element: lazyLoadRoutes("exitFullAndFinal/pages/exitFilterWindow"),
  },
  {
    path: routes.exitfullfinal.exitTypeFullAndFinalProcess,
    element: lazyLoadRoutes("exitFullAndFinal/pages/exitFullFinalProcess"),
  }
];
