import { useLocation, Navigate } from "react-router-dom";
import { getCookie } from './cookie-service';
import React from 'react';
export function PrivateRoute({ children }) {
    const auth = getCookie('veriipro-user');
    const location = useLocation();
    const isEmployer = location.pathname.indexOf('employer') > -1;

    if (!auth) {
        return <Navigate to={isEmployer ? "/employer/login" : "/login"} state={{ from: location }} />;
    }

    return children;
}