import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essOnBoardingRoutes = [
  {
    path: routes.ess.essOnBoarding.onBoardingList,
    element: lazyLoadRoutes(
      "ess/onBoarding/pages/GenerateOfferList/generateOfferList"
    ),
  },
  {
    path: routes.ess.essOnBoarding.onBoardingCtc,
    element: lazyLoadRoutes(
      "ess/onBoarding/pages/GenerateOfferList/ctcCalculation"
    ),
  },
];
