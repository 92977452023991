export const API_ENDPOINT = {
  admin: {
    login: "auth/admin/login",
  },
  ess: {
    login: "auth/ess/login",
    attendance: {
      punch: "/ess/punch",
      leaveRequest: "/ess/leave-request",
      leaveBalance: "/ess/leave-balance",
      calendarView: "ess/attendance/calendar-view",
      attendanceAnalytics: "ess/attendance/analytics",
    },
    assets: {
      assetsRequest: "/ess/assets-request",
      myAssets: "/ess/my-assets",
    },
    // essExitProcess
    essExitProcess: {
      myApplicationExitList: "/exitess/exitNewApplicationList/1",
      myApplicationExitAdd: "/exitess/addExitNewApplication",
      employeeMasterData: "/employee/getEmployee/1",
      myApplicationEmployeeData: "/exitess/getExitNewApplication/21",
      exitApprovalApplicationAdd: "/exitess/addExitApprovalApplication",
      ExitClearanceEmail: "/exitess/addExitEssEmployeeClearance",
      ExitCleranceList: "exitess/exitNewApplicationList/1",
      ExitSupervisorClearance: "exitess/addExitEssApprovalClearance",

      //Revoke process exit
      revokeAdd: "revoke/addExitRevokeApplication",
      //Ess Employee Exit Approval
      // Exit approval Part
      ExitApprovalApplicationadd: "",
      ExitApprovalApplicationList: "",
      ExitApprovalApplicationUpdate: "",
    },
    // essReimburshmentProcess
    essReimburshmentProcess: {
      travelTypeList: "reimbursement/getTravelType",
      travelRequisitionAdd: "essReimbursement/addTravelRequistion",
      travelRequisitionUpdate: "essReimbursement/updateTravelRequistion",
      travelRequisitionList: "essReimbursement/getEmployeeTravelRequisition",
      travelRequisitionDelete: "essReimbursement/deleteTravelRequistion",
      travelDetailsAdd: "essReimbursement/addTravelDetails",
      travelDetailsUpdate: "essReimbursement/addTravelDetails",
      travelDetailsUpdate: "essReimbursement/updateTravelDetails",
      travelDetailsList: "essReimbursement/travelDetailsList",
      travelDetailsgetById: "essReimbursement/deleteTravelDetails",
      travelDetailsDelete: "essReimbursement/deleteTravelDetails",
      accomodationAdd: "essReimbursement/addAccommodation",
      accomodationUpdate: "essReimbursement/updateAccommodation",
      accomodationDelete: "essReimbursement/deleteAccommodation",
      accomodationList: "essReimbursement/accommodationList",
      accomodationgetById: "essReimbursement/getAccommodationDetails",
      uploadDocumentAdd: "essReimbursement/addDocument",
      uploadDocumentUpdate: "essReimbursement/updateDocument",
      uploadDocumentDelete: "essReimbursement/deleteDocument",
      uploadDocumentList: "essReimbursement/documentList",
      uploadDocumentgetById: "essReimbursement/getDocumentDetails",
      claimLtaAdd:"reimbursement/addReimbursementClaimLta",
      otherLtaAdd:"reimbursement/addReimbursementOhterLta",
    },
  },
  forgot_password: "",
  otp_request: "",
  reset_password: "",
  register: "",
  master: {
    stateList: "getSateList",
    masterListCompanyId: "masterlist/masterList",
    masterCompanyInformationAdd: "master/addCompany",
    masterCompanyInformationUpdate: "master/updateCompany",
    masterCompanyInformationgetById: "master/getCompany",
    masterCompanyDocumentAdd: "master/addCompanyDocument",
    masterCompanyDocumentList: "master/DoucumentCompanyList",
    masterCompanyDocumentDelete: "master/deleteCompanyDocument",
    masterCompanyDocumentUpdate: "master/updateCompanyDocument",
    masterCompanyStatutory: "master/addStatutoryCompany",
    masterCompanyStatutoryUpdate: "master/updateStutoryCompany",
    masterCompanyStatutoryById: "master/statutoryCompanyList",
    masterCompanySetting: "master/addCompanySetting",
    masterCompanySettingUpdate: "master/updateCompanySetting",
    masterCompanySettingById: "master/companySetting",
    gradeList: "master/gradeList",
    getGrade: "master/getGrade",
    addGrade: "master/addGrade",
    updateGrade: "master/updateGrade",
    deleteGrade: "master/deleteGrade",
    departmentList: "master/departmentList",
    getDepartment: "master/getDepartment",
    addDepartment: "master/addDepartment",
    updateDepartment: "master/updateDepartment",
    deleteDepartment: "master/deleteDepartment",
    designationList: "master/designationList",
    getDesignation: "master/getDesignation",
    addDesignation: "master/addDesignation",
    updateDesignation: "master/updateDesignation",
    deleteDesignation: "master/deleteDesignation",
    // division
    divisionList: "master/divisionList",
    getDivision: "master/getDivision",
    addDivision: "master/addDivision",
    updateDivision: "master/updateDivision",
    deleteDivision: "master/deleteDivision",
    projectList: "master/projectList",
    getProject: "master/getProject",
    addProject: "master/addProject",
    updateProject: "master/updateProject",
    deleteProject: "master/deleteProject",
    bankList: "master/bankList",
    getBank: "master/getBank",
    addBank: "master/addBank",
    updateBank: "master/updateBank",
    deleteBank: "master/deleteBank",
    qualificationList: "master/qualificationList",
    addQualification: "master/addQualification",
    updateQualification: "master/updateQualification",
    getQualification: "master/getQualification",
    deleteQualification: "master/deleteQualification",
    prefixNameList: "master/prefixNameList",
    addPrefixName: "master/addPrefixName",
    updatePrefixName: "master/updatePrefixName",
    getPrefixName: "master/getPrefixName",
    deletePrefixName: "master/deletePrefixName",
    payrollCycleList: "master/payrollCycleList",
    getPayrollCycle: "master/getPayrollCycle",
    addPayrollCycle: "master/addPayrollCycle",
    updatePayrollCycle: "master/updatePayrollCycle",
    deletePayrollCycle: "master/deletePayrollCycle",
    idProofList: "master/idProofList",
    getIdProof: "master/getIdProof",
    addIdProof: "master/addIdProof",
    updateIdProof: "master/updateIdProof",
    deleteIdProof: "master/deleteIdProof",
    documentTypeList: "master/documentList",
    getDocumentType: "master/getDocument",
    addDocumentType: "master/addDocument",
    updateDocumentType: "master/updateDocument",
    deleteDocumentType: "master/deleteDocument",
    branchList: "master/branchList",
    getBranch: "master/getBranch",
    addBranch: "master/addBranch",
    editBranchList: "master/getBranch",
    updateBranch: "master/updateBranch",
    deleteBranch: "master/deleteBranch",
    companyList: "company/companyList",
    getCompany: "company/getCompany",
    addCompany: "company/addCompany",
    updateCompany: "company/updateCompany",
    deleteCompany: "company/deleteCompany",
    categoryList: "master/categoryList",
    getCategory: "master/getCategory",
    addCategory: "master/addCategory",
    updateCategory: "master/updateCategory",
    deleteCategory: "master/deleteCategory",
    lookUpList: "master/itemList",
    costCenterList: "master/costCenterList",
    getCostCenter: "master/getCostCenter",
    addCostCenter: "master/addCostCenter",
    updateCostCenter: "master/updateCostCenter",
    deleteCostCenter: "master/deleteCostCenter",
    skillList: "master/skillList",
    getSkill: "master/getSkill",
    addSkill: "master/addSkill",
    updateSkill: "master/updateSkill",
    deleteSkill: "master/deleteSkill",
    // Bonus
    bonusList: "master/bonusList",
    getBonus: "master/getBonus",
    addBonus: "master/addBonus",
    updateBonus: "master/updateBonus",
    deleteBonus: "master/deleteBonus",

    // location
    locationList: "master/locationList",
    locationAdd: "master/addLocation",
    locationGet: "master/getLocation",
    locationUpdate: "master/updateLocation",
    locationDelete: "master/deleteLocation",
  },
  employee: {
    employeeList: "employee/employeeList",
    employeeByCode: "reimbursement/getEmployeeDetails",
    addEmployee: "employee/addEmployee",
    updateEmployee: "employee/updateEmployee",
    getEmployee: "employee/getEmployee",
    deleteEmployee: "employee/deleteEmployee",
    getEmployeeWorkExperienceList: "employee/getEmployeeWorkExperienceAllList",
    getEmployeeWorkExperience: "employee/getEmployeeWorkExperience",
    addEmployeeWorkExperience: "employee/addEmployeeWorkExperience",
    updateEmployeeWorkExperience: "employee/updateEmployeeWorkExperience",
    deleteEmployeeWorkExperience: "employee/deleteEmployeeWorkExperience",
    getEmployeeSkills: "employee/getEmployeeSkillList",
    getEmployeeSkill: "employee/getEmployeeSkill",
    addEmployeeSkill: "employee/addEmployeeSkill",
    updateEmployeeSkill: "employee/updateEmployeeSkill",
    deleteEmployeeSkill: "employee/deleteEmployeeSkill",
    employeeQualificationList: "employee/employeeQualificationList",
    getEmployeeQualification: "employee/getEmployeeQualification",
    addEmployeeQualification: "employee/addEmployeeQualification",
    updateEmployeeQualification: "employee/updateEmployeeQualification",
    deleteEmployeeQualification: "employee/deleteEmployeeQualification",
    getEmployeePersonalDetails: "employee/getEmployeePersonalDetail",
    addEmployeePersonalDetails: "employee/addEmployeePersonalDetails",
    IdEmployeePersonalDetails: "employee/getEmployeePersonalDetails",
    updateEmployeePersonalDetails: "employee/updateEmployeePersonalDetails",
    getEmployeeHobbies: "employee/getEmployeeHobbyAllList",
    getEmployeeHobby: "employee/getEmployeeHobby",
    addEmployeeHobby: "employee/addEmployeeHobby",
    updateEmployeeHobby: "employee/updateEmployeeHobby",
    deleteEmployeeHobby: "employee/deleteEmployeeHobby",
    getEmployeeAchievements: "employee/getEmployeeAchievementAllList",
    getEmployeeAchievement: "employee/getEmployeeAchievement",
    addEmployeeAchievement: "employee/addEmployeeAchievement",
    updateEmployeeAchievement: "employee/updateEmployeeAchievement",
    deleteEmployeeAchievement: "employee/deleteEmployeeAchievement",
    getEmployeeContactDetails: "employee/getEmployeeContactDetails",
    addEmployeeContactDetails: "employee/addEmployeeContactDetails",
    updateEmployeeContactDetail: "employee/updateEmployeeContactDetails",
    getEmployeeDependentList: "employee/getEmployeeDependentAllList",
    getEmployeeDependentListEmpId: "employee/getEmployeeDependentList",
    getEmployeeDependent: "employee/getEmployeeDependent",
    addEmployeeDependent: "employee/addEmployeeDependent",
    updateEmployeeDependent: "employee/updateEmployeeDependent",
    deleteEmployeeDependent: "employee/deleteEmployeeDependent",
    employeeDocumentAdd: "employee/addEmployeeDocument",
    employeeDocumentUpdate: "employee/updateEmployeeDocument",
    employeeStatutoryAdd: "employee/addEmployeeStatutory",
    employeeStatutoryGetById: "employee/getEmployeeStatutoryList",
    employeeStatutoryUpdate: "employee/updateEmployeeStatutory",
    employeeMicsAllList: "employee/getEmployeeDocumentAll",
    employeeMicsDelete: "employee/deleteEmployeeDocument",
    employeeMedicalAdd: "employee/addEmployeeMedical",
    employeeMedicalUpdate: "employee/updateEmployeeMedical",
    employeeMedicalDelete: "employee/deleteEmployeeMedical",
    employeeMedicalInsuranceList: "employee/getEmployeeMedicalAllList",
  },
  attendance: {
    leaveTypeList: "attendance/leaveTypeList",
    getLeaveType: "attendance/getLeaveType",
    addLeaveType: "attendance/addLeaveType",
    updateLeaveType: "attendance/updateLeaveType",
    deleteLeaveType: "attendance/deleteLeaveType",
    leaveList: "attendance/leaveList",
    getLeave: "attendance/getLeave",
    addLeave: "attendance/addLeave",
    updateLeave: "attendance/updateLeave",
    deleteLeave: "attendance/deleteLeave",
    holidayList: "attendance/holidayList",
    getHoliday: "attendance/getHoliday",
    addHoliday: "attendance/addHoliday",
    updateHoliday: "attendance/updateHoliday",
    deleteHoliday: "attendance/deleteHoliday",
    leaveApplicationList: "attendance/leaveApplicationList",
    approveRejectLeaves: "attendance/approveRejectLeaves",

    empAttendanceList: "/attendance/empAttendanceList",
    empLeaveAnalyticsList: "/attendance/empLeaveAnalyticsList",
    empLeaveBalanceList1: "/attendance/empLeaveBalanceList1",
    empLeaveBalance: "/attendance/empLeaveBalance",
    empLeaveList: "/attendance/empLeaveList",
    addManualLeaveRequest: "/attendance/addManualLeaveRequest",

    getallAttendanceList: "/attendance/getallAttendanceList",
  },
  shift: {
    shiftList: "attendance/shiftList",
    getShift: "attendance/getShift",
    addShift: "attendance/addShift",
    updateShift: "attendance/updateShift",
    deleteShift: "attendance/deleteShift",
    shiftAssigedList: "attendance/employeeAssignShiftList",
    addShiftAssiged: "attendance/addEmployeeAssignShift",

    /* new as per nilansh api */
    assignShiftList: "assignshift/AssignShiftList",
  },

  assets: {
    assetsGroupList: "asset/assetsGroupList",
    addAssetsGroup: "asset/addAssetGroup",
    updateAssetsGroup: "asset/updateAssetGroup",
    deleteAssetsGroup: "asset/deleteAssetGroup",

    assetsMasterList: "asset/assetsMasterList",
    addAsset: "asset/addAsset",
    updateAsset: "asset/updateAsset",
    deleteAsset: "asset/deleteAsset",
    getAsset: "asset/getAsset/",

    assetsRequisitionList: "asset/assetRequisitionList",
    addAssetRequisitionRequest: "asset/addAssetRequisitionRequest",

    assetsRequestList: "asset/request-list",

    assetsMovementList: "asset/getAssetMovement",
    addAssetMovement: "asset/addAssetMovement",
  },
  exit: {
    // master list
    exitMasterTypeList: "exitType/exitTypeMasterList",
    exitDelete: "exitType/deleteExitTypeMaster",
    exitPost: "exitType/addExitTypeMaster",
    exitUpdate: "exitType/updateExitTypeMaster",
    // clearence list
    exitClearanceDepartmentList: "/approval/exitApprovalList",
    exitClearanceDepartmentPost: "/approval/addExitApproval",
    exitClearanceDepartmentDelete: "/approval/deleteExitApprovalGroup",
    exitClearanceDepartmentUpdate: "/approval/updateExitApprovalGroup",
    // notice list
    noticePeriodList: "noticeperiod/exitNoitcePeriodList",
    noticePeriodDelete: "noticeperiod/deleteExitNoitcePeriod",
    noticePeriodUpdate: "noticeperiod/updateExitNoitcePeriod",
    noticePeriodAdd: "noticeperiod/addExitNoitcePeriod",
    // InterViewIn list
    interViewInchargeList: "interview/interviewGroupList",
    interViewInchargeDelete: "interview/deleteInterviewGroup",
    interViewInchargeUpdate: "interview/updateInterviewGroup",
    interViewInchargeAdd: "interview/addInterviewGroup",
    // Clearance Descripition
    clearanceDepartmentList: "exitdepartment/exitDepartmentClearanceList",
    clearanceDepartmentDelete: "exitdepartment/deleteExitDepartmentClearance",
    clearanceDepartmentUpdate: "exitdepartment/updateExitDepartmentClearance",
    clearanceDepartmentAdd: "exitdepartment/addExitDepartmentClearance",
    // Questions Part
    questionsList: "exitquestion/exitTypeQuestionList",
    questionsDelete: "exitquestion/deleteExitTypeQuestion",
    questionsUpdate: "exitquestion/updateExitTypeQuestion",
    questionsAdd: "exitquestion/addExitTypeQuestion",
    questionsHeadingAdd: "exitType/addExitHeading",
    questionsHeadingUpdate: "exitType/updateExitHeading",
    questionsHeadingList: "exitType/exitHeading",
    questionsHeadingDelete: "exitType/deleteExitHeading",
    questionsTypeAdd: "exitType/addExitQuestion",
    questionsTypeUpdate: "exitType/updateExitQuestion",
    questionsTypeList: "exitType/exitQuestion",
    questionsTypeDelete: "exitType/deleteExitQuestion",
  },
  // payroll
  payroll: {
    payrollMasterListCompanyId: "payroll/ctcpayrollMasterList",
    payrollMasterHeaderItemList: "payroll/empIncremenTArrearEmployeeFilterList",
    // salary head
    payrollSalaryHeadList: "payroll/salaryHeadList",
    salaryHeadDelete: "payroll/deleteSalaryHead",
    salaryHeadPost: "payroll/addSalaryHead",
    salaryHeadUpdate: "payroll/updateSalaryHead",
    payrollSalaryHeadId: "payroll/getSalaryHead",
    // Deducation head
    payrollDeductionHeadList: "payroll/deductionHeadList",
    payrollDeductionHeadId: "payroll/getDeductionHead",
    DeductionHeadDelete: "payroll/deleteDeductionHead",
    DeductionHeadPost: "payroll/addDeductionHead",
    DeductionHeadUpdate: "payroll/updateDeductionHead",

    // Other head
    payrollOtherHeadList: "payroll/otherHeadList",
    payrollOtherHeadDelete: "payroll/deleteOtherHead",
    payrollOtherHeadAdd: "payroll/addOtherHead",
    payrollOtherHeadUpdate: "payroll/updateOtherHead",
    payrollOtherHeadId: "payroll/getOtherHead",

    // Gournal Head
    payrollJournalList: "payroll/journalVoucherList",
    payrollJournalDelete: "payroll/deleteJournalVoucher",
    payrollJournalAdd: "payroll/addJournalVoucher",
    payrollJournalUpdate: "payroll/updateJournalVoucher",

    // OverTime
    OvertimeList: "payroll/otSetting",
    OvertimeAdd: "payroll/addOtSetting",
    OvertimeUpdate: "payroll/updateOtSetting",

    // OtUploadAttandence
    OtAttendanceList: "payroll/otAttendanceAllList",
    OtAttendanceAdd: "payroll/addOtAttendance",
    OtAttendanceUpdate: "payroll/updateOtAttendance",
    OtAttendanceDelete: "payroll/deleteOtAttendance",

    // LWF
    LwfList: "payroll/otLwfSetting",
    LwfAdd: "payroll/addLwfSetting",
    LwfUpdate: "payroll/updateLwfSetting",
    LwfDelete: "payroll/deleteLwfSetting",

    // Professional Tax
    ProfessionalTaxList: "payroll/ptSlab",
    ProfessionalTaxAdd: "payroll/addPtSlab",
    ProfessionalTaxUpdate: "payroll/updatePtSlab",
    ProfessionalTaxDelete: "payroll/deletePtSlab",
    ProfessionalTaxId: "payroll/getPtSlab",

    // ctc component setting
    CtcComponentList: "payroll/ctcStructureList",
    CtcComponentAdd: "payroll/addCtcStructure",
    CtcComponentUpdate: "payroll/updateCtcStructure",
    CtcComponentDelete: "payroll/deleteCtcStructure",
    CtcComponentAddId: "payroll/getCtcStructure",
    CtcComponentAddCtc: "payroll/ctcCalculation",
    CtcComponentAddCtcCalculated: "payroll/addSalaryStructureCtc",
    CtcComponentAddCtcSaveData: "payroll/getSalaryStructureCtc",

    // Leave Encashment
    LeaveEncashmentList: "payroll/leaveEncashmentList",
    LeaveEncashmentAdd: "payroll/addLeaveEncashment",
    LeaveEncashmentUpdate: "payroll/updateLeaveEncashment",
    LeaveEncashmentDelete: "payroll/deleteLeaveEncashment",

    // salarystructurehead
    SalaryStructureHeadList: "payroll/salaryStructureHeadList",
    SalaryStructureHeadAdd: "payroll/addSalaryStructureHead",
    SalaryStructureHeadUpdate: "payroll/updateSalaryStructure",
    SalaryStructureHeadDelete: "payroll/deleteSalaryStructure",

    //salaryStructureCtc
    SalaryStructureCtc: "payroll/salaryStructureList",
    SalaryStructureCtcAdd: "payroll/addSalaryStructureHead",
    SalaryStructureCtcUpdate: "payroll/updateSalaryStructureHead",
    SalaryStructureCtcDelete: "payroll/deleteSalaryStructureHead",

    IncrementArrearAdd: "payroll/addSalaryArrear",
    IncrementArrearUpdate: "payroll/updateSalaryIncrement",
    IncrementArrearList: "payroll/salaryIncrementList",
    IncrementArrearListNew: "payroll/salaryArrearList",
    IncrementArrearById: "payroll/getSalaryIncrement",
    IncrementArrearDel: "payroll/deleteSalaryArrear",

    // BonusCalculation
    BonusCalculationList: "calculation/BonusCalculationList",
    BonusCalculationAdd: "calculation/addBonusCalculation",
    BonusCalculationDelete: "calculation/deleteBonusCalculation",
    BonusCalculationUpdate: "calculation/updateBonusCalculation",
    BonusCalculationGetId: "calculation/getBonusCalculation",

    // Lwp Arrear
    LwpArrearList: "payroll/salaryArrearlwpList",
    LwpArrearAdd: "payroll/addSalaryArrearlwp",
    LwpArrearUpdate: "payroll/updateSalaryArrearlwp",
    LwpArrearGetId: "payroll/getSalaryArrearlwp",
    LwpArrearDelete: "payroll/deleteSalaryArrearlwp",
    // Lwp Arrear
    OtherArrearList: "payroll/salaryArrearotherList",
    OtherArrearAdd: "payroll/addSalaryArrearother",
    OtherArrearUpdate: "payroll/updateSalaryArrearother",
    OtherArrearGetId: "payroll/getSalaryArrearother",
    OtherArrearDelete: "payroll/deleteSalaryArrearother",

    //Gratuity
    GratuityList: "payroll/gratuityListAll",
    GratuityAdd: "payroll/addGratuity",
    GratuityUpdate: "payroll/updateGratuity",
    GratuityDelete: "payroll/deleteGratuity",
    // Promotion
    PromotionList: "payroll/promotionTransferList",
    PromotionAdd: "payroll/addPromotionTransfer",
    PromotionUpdate: "payroll/updatePromotionTransfer",
    PromotionDelete: "payroll/deletePromotionTransfer",
    PromotionGetId: "payroll/getPromotionTransfer",

    // Employee Increment
    EmployeeIncrementList: "payroll/salaryIncrementList",
    EmployeeIncrementAdd: "payroll/addSalaryIncrement",
    EmployeeIncrementByEmpId: "payroll/getSalaryStructureEmpList",
    EmployeeIncrementUpdate: "payroll/updateSalaryIncrement",
    EmployeeIncrementDelete: "payroll/deleteSalaryIncrement",
    EmployeeIncrementGetId: "payroll/getSalaryIncrement",
    EmployeeIncrementByIdView: "payroll/getSalaryIncrementEmpList",
    EmployeeIncrementDaysAdd: "payroll/employeeDays",
  },

  reimbursement: {
    travelTypeList: "reimbursement/getTravelTyPeList",
    travelAdd: "reimbursement/addTravelPolicy",
    travelPolicyList: "reimbursement/travelPolicyList",
    travelPolicyDelete: "reimbursement/deleteTravelPolicy",
    approvalMatrixList: "reimbursement/approalMatrixList",
    approvalMatrixAdd: "reimbursement/addApproalMatrix",
    approvalMatrixDelete: "reimbursement/deleteApproalMatrix",
    approvalMatrixUpdate: "reimbursement/updateApproalMatrix",
    approvalMatrixFilterAdd: "reimbursement/addApproalFilterMatrix",
    settingFaceList: "reimbursement/reimbursementSettingList",
    settingFaceAdd: "reimbursement/addReimbursementSetting",
    processAdminAdd: "reimbursement/addReimbursement",
    processAdminUpdate: "reimbursement/updateReimbursement",
    processAdminDelete: "reimbursement/deleteReimbursement",
    processAdminList: "reimbursement/reimbursementList",
    processAdminGetById: "reimbursement/getReimbursementAdd",
  },
  user: {
    adminRole: "user/admin-role",
    essRole: "user/ess-role",
    admin: "user/admin",
    ess: "user/ess",
  },
};
