import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const monthlyCalculationRoutes = [
  {
    path: routes.monthlyCalculation.monthlyCalculation,
    element: lazyLoadRoutes("monthly-calculation/pages/monthlyCalculation"),
  },
  {
    path: routes.monthlyCalculation.deductionVariable,
    element: lazyLoadRoutes("monthly-calculation/pages/deductionVariables"),
  },
];
