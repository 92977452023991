import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const controlRoutes = [
  {
    path: routes.controls.uploadControl,
    element: lazyLoadRoutes("controls/pages/uploadControl"),
  },
  {
    path: routes.controls.announcement,
    element: lazyLoadRoutes("controls/pages/announcement"),
  },
  {
    path: routes.controls.policies,
    element: lazyLoadRoutes("controls/pages/policies"),
  },
  {
    path: routes.controls.news,
    element: lazyLoadRoutes("controls/pages/news"),
  },
  {
    path: routes.controls.eventGallery,
    element: lazyLoadRoutes("controls/pages/event-gallery"),
  },
  {
    path: routes.controls.approvalSetting,
    element: lazyLoadRoutes("controls/pages/approval-setting"),
  },
  {
    path: routes.controls.importUtility,
    element: lazyLoadRoutes("controls/pages/import-utility"),
  },

  {
    path: routes.controls.softwareLogReport,
    element: lazyLoadRoutes("controls/pages/software-log-report"),
  },
];
