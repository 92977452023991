import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essReimbursementProcessRoutes = [
    {
      path: routes.ess.essReimbursementProcess.travelRequestList,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequest/travelRequestList"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelRequestAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequest/travelRequisitionAdd"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.travelRequestEdit}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequest/travelRequisitionEdit"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.travelRequestView}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequest/travelRequisitionView"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelRequestApprovalList,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequestApproval/travelRequestApprovalList"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelRequestApprovalAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelRequestApproval/travelRequisitionApprovalAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.hrAdminApprovalList,
      element: lazyLoadRoutes("ess/reimbursement/pages/hrAdminTravelApproval/hrAdminApprovalList"),
    },
    {
      path: routes.ess.essReimbursementProcess.hrAdminApprovalAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/hrAdminTravelApproval/hrAdminApprovalAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.financeDepartmentList,
      element: lazyLoadRoutes("ess/reimbursement/pages/financeDepartment/financeDepartmentList"),
    },
    {
      path: routes.ess.essReimbursementProcess.financeDepartmentAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/financeDepartment/financeDepartmentAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaTravelList,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelEmployeeList"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaTravelAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelEmployeeAdd"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.otherLtaTravelEdit}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelEmployeeEdit"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.otherLtaTravelView}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelEmployeeView"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaApprovedList,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelApprovedList"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaApprovedAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelApprovedAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaFinanceApprovedList,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelFinanceApprovedList"),
    },
    {
      path: routes.ess.essReimbursementProcess.otherLtaFinanceApprovedAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/otherMics/travelFinanceApprovedAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimList,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimList"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimAdd"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.ltaClaimEdit}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimEdit"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.ltaClaimView}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimView"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimApprovedList,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimApproverList"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimApprovedAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimApproverAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimFinanceApprovedList,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimFinanceApproverList"),
    },
    {
      path: routes.ess.essReimbursementProcess.ltaClaimFinanceApprovedAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/ltaClaim/ltaClaimFinanceApproverAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelClaimWindowList,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowList"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelClaimWindowAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowAdd"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.travelClaimWindowEdit}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowEdit"),
    },
    {
      path: `${routes.ess.essReimbursementProcess.travelClaimWindowView}/:id`,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowView"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelClaimWindowApproverList,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowApproverList"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelClaimWindowApproverAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelClaimWindowApproverAdd"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelPaymentProcessList,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelPaymentProcessList"),
    },
    {
      path: routes.ess.essReimbursementProcess.travelPaymentProcessAdd,
      element: lazyLoadRoutes("ess/reimbursement/pages/travelClaimWindow/travelPaymentProcessAdd"),
    },
];