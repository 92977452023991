import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const masterRoutes = [
  {
    path: routes.master.addCompany,
    element: lazyLoadRoutes("masters/company/add"),
  },
  {
    path: `${routes.master.addCompany}/:id`,
    element: lazyLoadRoutes("masters/company/add"),
  },
  {
    path: routes.master.listCompany,
    element: lazyLoadRoutes("masters/company/list"),
  },
  {
    path: routes.master.addCompanyNew,
    element: lazyLoadRoutes("masters/company-new/add"),
  },
  {
    path: `${routes.master.addCompanyNew}/:id`,
    element: lazyLoadRoutes("masters/company-new/add"),
  },
  {
    path: routes.master.listCompanyNew,
    element: lazyLoadRoutes("masters/company-new/list"),
  },
  {
    path: routes.master.qualification,
    element: lazyLoadRoutes("masters/qualification/pages/qualification"),
  },
  {
    path: routes.master.project,
    element: lazyLoadRoutes("masters/project/pages/project"),
  },
  {
    path: routes.master.grade,
    element: lazyLoadRoutes("masters/grade/pages/grade"),
  },
  {
    path: routes.master.division,
    element: lazyLoadRoutes("masters/division/division"),
  },
  {
    path: routes.master.divisionAdd,
    element: lazyLoadRoutes("masters/division/divisionAdd"),
  },
  {
    path: `${routes.master.divisionEdit}/:id`,
    element: lazyLoadRoutes("masters/division/divisionEdit"),
  },
  {
    path: routes.master.designation,
    element: lazyLoadRoutes("masters/designation/pages/designation"),
  },
  {
    path: routes.master.department,
    element: lazyLoadRoutes("masters/department/pages/department"),
  },
  {
    path: routes.master.category,
    element: lazyLoadRoutes("masters/category/pages/category"),
  },
  {
    path: routes.master.branch,
    element: lazyLoadRoutes("masters/branch/branch"),
  },
  {
    path: routes.master.branchAdd,
    element: lazyLoadRoutes("masters/branch/branchAdd"),
  },
  {
    path: `${routes.master.branchEdit}/:id`,
    element: lazyLoadRoutes("masters/branch/branchEdit"),
  },
  {
    path: routes.master.bank,
    element: lazyLoadRoutes("masters/bank/pages/bank"),
  },
  {
    path: routes.master.prefixName,
    element: lazyLoadRoutes("masters/prefix-name/prefix-name"),
  },
  {
    path: routes.master.payrollCycle,
    element: lazyLoadRoutes("masters/payroll-cycle/payroll-cycle"),
  },
  {
    path: routes.master.idProof,
    element: lazyLoadRoutes("masters/id-proof/id-proof"),
  },
  {
    path: routes.master.documentType,
    element: lazyLoadRoutes("masters/document-type/pages/document-type"),
  },
  {
    path: routes.master.costCenter,
    element: lazyLoadRoutes("masters/cost-center/pages/cost-center"),
  },
  {
    path: routes.master.skills,
    element: lazyLoadRoutes("masters/skills/pages/skills"),
  },
  {
    path: routes.master.location,
    element: lazyLoadRoutes("masters/location/location"),
  },
  {
    path: routes.master.locationAdd,
    element: lazyLoadRoutes("masters/location/locationAdd"),
  },
  {
    path: `${routes.master.locationEdit}/:id`,
    element: lazyLoadRoutes("masters/location/locationEdit"),
  },
  {
    path: routes.master.bonus,
    element: lazyLoadRoutes("masters/bonus/pages/bonus"),
  },
  {
    path: routes.master.essentials,
    element: lazyLoadRoutes("masters/essentials/pages/essentials"),
  },
];
