export const SHOW_LOADING = "SHOW_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_USER = 'UPDATE_USER';
export const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const UPDATE_EMPLOYEE_ID = 'UPDATE_EMPLOYEE_ID';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_LIST = 'UPDATE_COMPANY_LIST';
export const UPDATE_ACTIVE_MONTH = 'UPDATE_ACTIVE_MONTH';
export const UPDATE_SIDEBAR = 'UPDATE_SIDEBAR';

