import { useRoutes } from "react-router-dom";
import { lazyLoadRoutes } from "../utils/lazy-loader";
import routes from "../constants/routes";
import { masterRoutes } from "./master-routes";
import { leaveRoutes } from "./leave-routes";
import { shiftRoutes } from "./shift-routes";
import { assetsRoutes } from "./assets-routes";
import { payrollRoutes } from "./payroll-routes";

export function AuthRoutes() {
    const authRoutes = [{
      path: "/",
      children: [
        {
            index: true,
            element: lazyLoadRoutes("auth/login"),
        },
        {
            path: "auth/login",
            element: lazyLoadRoutes("auth/login"),
        },
        {
            path: "auth/forgot-password",
            element: lazyLoadRoutes("auth/forgot-password"),
        },
        ],
    }];

  return useRoutes(authRoutes);
}
