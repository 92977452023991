import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essTdsCalculationProcess = [
  {
    path: routes.ess.essTdsCalculationProcess.tdsCalculation,
    element: lazyLoadRoutes("ess/tds/pages/tdsCalculation/tdsCalculation"),
  },
  {
    path: routes.ess.essTdsCalculationProcess.tdsApprover,
    element: lazyLoadRoutes("ess/tdsApprover/pages/baseWindow/baseWindow"),
  },
  {
    path: routes.ess.essTdsCalculationProcess.tdsProofChecking,
    element: lazyLoadRoutes(
      "ess/tdsApprover/pages/employeeProofChecking/employeeProofChecking"
    ),
  },
];
