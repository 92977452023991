import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const employeeConfirmDaysRoutes = [
  {
    path: routes.employeeConfirmation.listEmployeeConfirm,
    element: lazyLoadRoutes(
      "employee-confirmation/pages/employeeConfirmationList"
    ),
  },
];
