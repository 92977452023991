import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const tdsRoutes = [
  {
    path: routes.tds.TDSSettings,
    element: lazyLoadRoutes("tds/pages/tdsSettings/tdsSettings"),
  },
  {
    path: routes.tds.TDSUSVIAItems,
    element: lazyLoadRoutes("tds/pages/usVAItems/usVAItems"),
  },
  {
    path: routes.tds.TDSPerksMaster,
    element: lazyLoadRoutes("tds/pages/perksMaster/perksMaster"),
  },
  {
    path: routes.tds.TDSIncomeArrearExemption,
    element: lazyLoadRoutes(
      "tds/pages/incomeArrearExemption/incomeArrearExemption"
    ),
  },
  {
    path: `${routes.tds.TDSperquisite}/:id`,
    element: lazyLoadRoutes("tds/pages/incomeArrearExemption/perquisiteview"),
  },
  {
    path: routes.tds.TDSBaseWindow,
    element: lazyLoadRoutes("tdsApprover/pages/baseWindow/baseWindow"),
  },
  {
    path: routes.tds.TDSEmployeeProofChecking,
    element: lazyLoadRoutes(
      "tdsApprover/pages/employeeProofChecking/employeeProofChecking"
    ),
  },
  // tds Return process
  {
    path: routes.tds.TDSChallan,
    element: lazyLoadRoutes("tdsReturnProcess/pages/tdsChallan/tdsChallan"),
  },

  {
    path: routes.tds.TDSCompanyDetails,
    element: lazyLoadRoutes("tdsReturnProcess/pages/details/details"),
  },
  {
    path: routes.tds.TDSChallanDetails,
    element: lazyLoadRoutes(
      "tdsReturnProcess/pages/tdsChallanDetails/tdsChallanDetails"
    ),
  },
  {
    path: routes.tds.TDSChallanReport,
    element: lazyLoadRoutes(
      "tdsReturnProcess/pages/tdsChallanReport/tdsChallanReport"
    ),
  },
  {
    path: routes.tds.TDSSalryDetail,
    element: lazyLoadRoutes(
      "tdsReturnProcess/pages/tdsChallanDetails/insertSalaryDetail"
    ),
  },
];
