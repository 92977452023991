import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const essLoanRoutes = [
  {
    path: routes.ess.loan.loanApply,
    element: lazyLoadRoutes(
      "ess/loanAndAdvanced/pages/LoanApplyProcess/loanApplyList"
    ),
  },
  {
    path: routes.ess.loan.loanApprove,
    element: lazyLoadRoutes(
      "ess/loanAndAdvanced/pages/LoanApproverProcess/loanApproveList"
    ),
  },
  {
    path: routes.ess.loan.loanApproverView,
    element: lazyLoadRoutes(
      "ess/loanAndAdvanced/pages/LoanApproverProcess/loanApproverView"
    ),
  },
  {
    path: routes.ess.loan.loanFinanceList,
    element: lazyLoadRoutes(
      "ess/loanAndAdvanced/pages/LoanFinanceProcess/loanFinanceList"
    ),
  },
  {
    path: `${routes.ess.loan.loanFinanceView}/:id`,
    element: lazyLoadRoutes(
      "ess/loanAndAdvanced/pages/LoanFinanceProcess/loanFinanceView"
    ),
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanApproverProcess/loanApproverView"),
  },
  {
    path: routes.ess.loan.loanFinanceApprover,
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanFinanceApprover/loanFinanceApproverList"),
  },
  {
    path: routes.ess.loan.loanFinanceView,
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanFinanceApprover/loanFinanceView"),
  },
  {
    path: routes.ess.loan.loanReport,
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanReports/empDetailsReports"),
  },
  {
    path: routes.ess.loan.loanApprovalReport,
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanReports/approverLoanAdvDetails"),
  },
  {
    path: `${routes.ess.loan.loanApprovalViewReport}/:id`,
    element: lazyLoadRoutes("ess/loanAndAdvanced/pages/LoanReports/approverView"),
  },
];
